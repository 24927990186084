import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const WhitePaperPage = (props) => {
    const components = props.data.cms.whitePaperPageTemplate?.components || null;
    const splitBackground = props.data.cms.employeesInfoTemplate?.splitBackground;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton navigateToForm="#form"/>
            <DynamicZone
                components={components}
                splitBackground={splitBackground}
            />
        </Layout>
    );
};

export default WhitePaperPage;

export const query = graphql`
query ($locale: String!){
  cms {
    employeesInfoTemplate(locale: $locale) {
      splitBackground {
        bottomItems
        leftSide {
          title
          content
        }
        rightSide {
          description
          imageData
          title
          images {
            url
          }
        }
        topPusherHeight
        topPusherHeightMobile
      }
    }
    whitePaperPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsImagesWithOffset {
            __typename
            content {
              image {
                url
              }
              title
              subtitle
            }
            bottomText
            contentOffset
            imagesWithOffsetScrollColor
            offsetComponentType
            topPusherHeight
            topPusherHeightMobile
            sectionTitle {
              description
              style
              tailwindClasses
              title
              titleIcon {
                url
              }
              ytLink
            }
          }
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          scrollButton {
            link
            label
          }
          backgroundImage {
            url
          }
          backgroundImageHeight
          contentOffset
          mobileContentOffset
          primaryImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsTheyTrustUs {
          __typename
          linesVisible
          images {
            url
          }
          mobileImages {
            url
          }
          theyTrustUsScrollColor
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            ytLink
            title
            tailwindClasses
            style
            description
          }
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            style
            tailwindClasses
            title
            description
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsImagesWithOffset {
          __typename
          bottomText
          content {
            title
            subtitle
            image {
              url
            }
          }
          contentOffset
          imagesWithOffsetScrollColor
          offsetComponentType
          sectionTitle {
            description
            style
            tailwindClasses
            title
            ytLink
          }
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentEmployeesListBoardList {
          __typename
          sectionTitle {
            ytLink
            title
            tailwindClasses
            style
            description
          }
          topPusherHeight
          topPusherHeightMobile
          employee {
            employee {
              bio
              city
              image {
                url
              }
              isFromBoard
              nameAndSurname
              title
              socials {
                hrefContent
                icon {
                    url
                }
              }
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          sectionTitle {
            description
            style
            tailwindClasses
            title
            ytLink
          }
          tabHeight
          tabsContentColor
          tabsDarkBackground
          topPusherHeight
          topPusherHeightMobile
          tabs {
            label
            type
            title
            subtitle
            images {
              url
            }
            imagesMetadata
            formFieldsData
            sendButtonLabel
          }
        }
        ... on STRAPI_ComponentComponentsOffsetImageWithList {
          __typename
          topPusherHeightMobile
          topPusherHeight
          title
          offsetImageSubtitle: subtitle
          setComponentPadding
          listItemImageTailwindClasses
          isReverted
          mobileImageHeight
          isImageVisibleOnMobile
          imageType
          imageOffset
          imageHeight
          hasLightBackground
          image {
            url
          }
          ImageWithText {
            content
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentWhitePaperSingaporeSupport {
          __typename
          upperText
          image {
            url
          }
        }
        ... on STRAPI_ComponentWhitePaperTripleOffices {
          __typename
          topPusherHeightMobile
          topPusherHeight
          warsawOffice {
            officesData
            title
          }
          singaporeOffice {
            officeData
            title
            vkImage {
              url
            }
          }
          sectionTitle {
            ytLink
            title
            tailwindClasses
            style
            description
          }
          londonOffice {
            officesData
            title
          }
          images {
            url
          }
        }
        ... on STRAPI_ComponentWhitePaperBottomLogosWithLine {
          __typename
          topPusherHeight
          topPusherHeightMobile
          logos {
            url
          }
        }
        ... on STRAPI_ComponentWhitePaperHasEmployeesInfo {
          __typename
        }
        ... on STRAPI_ComponentComponentsRichTextOnly {
          __typename
          richTextOnlyContent
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentWhitePaperDownloadForm {
          __typename
          buttonLabel
          companyLabel
          elementId
          emailAddressLabel
          formTitle
          nameAndSurnameLabel
          privacyPolicyLabel
          sideImage {
            url
          }
          topPusherHeight
          topPusherHeightMobile
        }
      }
    }
  }
}
`;
